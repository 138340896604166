// inert polyfill
import "wicg-inert";

const DATA_OPEN_TOGGLE = 'data-dialog-open';
const CLASS_CLOSE_TOGGLE = '.modal__close';
const CLASS_NAME_OPEN = 'open';
const CLASS_NAME_SHOW = 'show';

class Dialog {
    constructor(dialogParent, parentOverride = false, enableBodyClass = false) {
        this.parentOverride = parentOverride;
        this.enableBodyClass = enableBodyClass;
        this.dialogParent = document.querySelector(dialogParent);
        this.openToggle = this.dialogParent.querySelector(`[${DATA_OPEN_TOGGLE}]`);
        this.dialogId = this.openToggle.getAttribute(DATA_OPEN_TOGGLE);
        this.dialogEl = document.querySelector(`#${this.dialogId}`);
        this.closeToggle = this.dialogEl.querySelector(CLASS_CLOSE_TOGGLE);
        this.dialogOverlay = this.dialogEl.querySelector('.modal__overlay');

        this.escapeEl = this.parentOverride ? this.dialogParent : this.dialogEl;

        this._handleKeyDown = this._handleKeyDown.bind(this);

        this.addListeners();
    }

    // Getters & Setters
    get isShown() {
        return this._isShown;
    }
    get focusedElBeforeOpen() {
        return this._focusedElBeforeOpen;
    }

    get firstFocusableEl() {

    }

    set isShown(isShown) {
        this._isShown = isShown;
    }
    set focusedElBeforeOpen(focusedElBeforeOpen) {
        this._focusedElBeforeOpen = focusedElBeforeOpen;
    }

    set firstFocusableEl(firstFocusableEl) {
        this.firstFocusableEl = firstFocusableEl;
    }

    addListeners() {
        if (this.openToggle && this.closeToggle) {
            this.openToggle.addEventListener('click', () => {
                this.show();
            });
            this.closeToggle.addEventListener('click', () => {
                this.hide();
            });
        } else {
            if (this.openToggle) {
                this.openToggle.addEventListener('click', () => {
                    this.toggle();
                });
            } else {
                console.log('Missing dialog toggle.');
            }
        }

        if (this.dialogOverlay) {
            this.dialogOverlay.addEventListener('click', () => {
               this.hide();
            });
        }
    }

    toggle() {
        if (this._isShown) {
            this.hide();
        } else {
            this.show();
        }
    }
    show() {
        this.isShown = true;
        this._handleAriaAndCollapsedClass(this.openToggle, true);
        this.dialogEl.classList.add(CLASS_NAME_SHOW);
        this._handleChildInert();

        if (this.enableBodyClass) {
            document.body.classList.add(CLASS_NAME_OPEN);
        }

        this._escapeEvent();

        this.focusedElBeforeOpen = document.activeElement;

        if (!this.parentOverride) {
            let firstFocusableEl = this._getKeyboardFocusableElements(this.dialogEl)[0];
            firstFocusableEl.focus();
        }
    }
    hide() {
        this.isShown = false;
        this._handleAriaAndCollapsedClass(this.openToggle, false);
        this.dialogEl.classList.remove(CLASS_NAME_SHOW);
        this._handleChildInert();

        document.body.classList.remove(CLASS_NAME_OPEN);

        this._escapeEvent();

        this.focusedElBeforeOpen.focus();
    }

    _handleAriaAndCollapsedClass(toggler, isOpen) {
        if (isOpen) {
            toggler.setAttribute('aria-expanded', true);
        } else {
            toggler.setAttribute('aria-expanded', false);
        }
    }

    _handleChildInert() {
        let allFocusableEls = this._getKeyboardFocusableElements();
        let dialogFocusableEls = this.parentOverride ? this._getKeyboardFocusableElements(this.dialogParent) : this._getKeyboardFocusableElements(this.dialogEl);

        [...allFocusableEls].forEach((child) => {
            child.inert = this._isShown;
        });

        [...dialogFocusableEls].forEach((child) => {
            child.inert = false;
        });
    }

    _escapeEvent() {
        if (this._isShown) {
            this.escapeEl.addEventListener('keydown', this._handleKeyDown);
        } else {
            this.escapeEl.removeEventListener('keydown', this._handleKeyDown);
        }
    }

    _handleKeyDown(event) {
        const KEY_ESC = 'Escape';
        if (event.key === KEY_ESC) {
            event.preventDefault();
            this.hide();
        }
    }

    _getKeyboardFocusableElements(element = document) {
        return [
            ...element.querySelectorAll(
                'a[href], area[href], input:not([type=hidden]):not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
            ),
        ]
    }
}

export default Dialog